import { merge } from "theme-ui";
import { deep } from "@theme-ui/presets";

import layout from "./layout";
import colors from "./colors";
import styles from "./styles";
import links from "./links";
import tags from "./tags";
import prism from "./prism";

export default merge(deep, {
  initialColorMode: "dark",
  useCustomProperties: true,
  layout,
  colors,
  styles,
  links,
  prism,
  tags,
  easings: {
    inQuad: `cubic-bezier(0.55, 0.085, 0.68, 0.53)`,
    inQuart: `cubic-bezier(0.895, 0.03, 0.685, 0.22)`,
    outQuad: `cubic-bezier(0.25, 0.46, 0.45, 0.94)`,
    outQuart: `cubic-bezier(0.165, 0.84, 0.44, 1)`,
    inOutQuad: `cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
    inOutQuart: `cubic-bezier(0.77, 0, 0.175, 1)`,
  },
  fonts: {
    body: `"SF Pro Display", -apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif`,
  },
  dividers: {
    bottom: {
      borderBottomStyle: `solid`,
      borderBottomWidth: `1px`,
      borderBottomColor: `divide`,
      pb: 3,
    },
    post: {
      position: `relative`,
      width: `80%`,
      border: 0,
      height: `14px`,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='15' viewBox='0 0 10 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.567383' y='14.1777' width='16' height='1' transform='rotate(-60 0.567383 14.1777)' fill='%2373737D65'/%3E%3C/svg%3E")`,
      backgroundRepeat: `repeat-x`,
      boxSizing: `border-box`,
      backgroundPosition: `center`,
      mt: 5,
      mb: 5,
    },
    top: {
      borderTopStyle: `solid`,
      borderTopWidth: `1px`,
      borderTopColor: `divide`,
      pt: 3,
    },
  },
});
