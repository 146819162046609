/* eslint react/prop-types: 0 */
import React from "react"
import { preToCodeBlock } from "mdx-utils"
import { Text } from "@theme-ui/components"
import Code from "@lekoarts/gatsby-theme-minimal-blog/src/components/code"
import Title from "@lekoarts/gatsby-theme-minimal-blog/src/components/title"
import Repository from "../@lekoarts/gatsby-theme-minimal-blog/components/repository"

const components = {
  Text: ({ children, ...props }) => <Text {...props}>{children}</Text>,
  H1: ({ children, ...props }) => <Text as="h1" {...props}>{children}</Text>,  
  Title: ({ children, text, ...props }) => (
    <Title text={text} {...props}>
      {children}
    </Title>
  ),
  pre: (preProps) => {
    const props = preToCodeBlock(preProps)
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />
    }
    // it's possible to have a pre without a code in it
    return <pre {...preProps} />
  },
  wrapper: ({ children }) => <>{children}</>,
Repository: ({children, ...props}) => <Repository {...props}>{children}</Repository>
}

export default components
