export default {
  secondary: {
    color: `secondary`,
    textDecoration: `none`,
    fontWeight: 500,
    ":hover": {
      color: `text`,
      textDecoration: `none`,
    },
  },
  listItem: {
    fontSize: [3, 4, 4],
    color: `text`,
  },
};
