export default {
  pre: {
    variant: `prism`,
    fontFamily: `"Operator Mono", monospace`,
    tabSize: 4,
    hyphens: `none`,
    color: `red`,
    bg: `red`,
    overflow: `auto`,
    borderRadius: 10,
    p: 3,
    code: {
      color: "red",
    },
  },
  code: {
    fontFamily: `"Operator Mono", monospace`,
    fontSize: `inherit`,
    color: "red",
  },
  inlineCode: {
    color: "red",
  },
  figcaption: {
    color: `grey`,
    fontSize: 2,
    textAlign: `center`,
    width: `100%`,
    mt: [-3, -3, -4],
    mb: 4
  },
  repository: {
    textDecoration: "none",
    h5: {
      margin: 0,
      fontWeight: `bold`,
      img: {
        ml: 1,
        verticalAlign: `text-bottom`,
      },
    },
    p: {
      color: `secondary`,
      mt: 1
    },
    ":hover": {
      h5: {
        color: `accent`
      },
    },
  }
};
