/** @jsx jsx */
import { jsx } from "theme-ui";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src//utils/replaceSlashes";
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src//hooks/use-site-metadata";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src//hooks/use-minimal-blog-config";

interface RepositoryProps {
  name: string,
  slug: string,
  package: string
  description: string
}

const Repository = ({
  name,
  slug,
  package: packageName,
  description
}: RepositoryProps) => {
  return (
    <a
      href={`https://github.com/gorhom/${slug}`}
      target="_blank"
      rel="noreferrer"
      sx={{
        variant: 'tags.repository'
      }}
    >
      <h5>
        {name} 
        <img src={`https://badgen.net/npm/v/${packageName}`} />
        <img src={`https://badgen.net/github/license/gorhom/${slug}`} />
      </h5>
      <p>{description}</p>
    </a>
  );
};

export default Repository;
