const styles = {
  root: {
    color: `text`,
    backgroundColor: `background`,
    margin: 0,
    padding: 0,
    fontSize: 3,
    boxSizing: `border-box`,
    textRendering: `optimizeLegibility`,
    figcaption: { variant: `tags.figcaption` },
  },
  p: {
    fontSize: 3,
    letterSpacing: `-0.003em`,
    lineHeight: 1.75,
    "--baseline-multiplier": 0.179,
    "--x-height-multiplier": 0.35,
    mt: 0,
    mb: 4,
  },
  ul: {
    li: {
      fontSize: 3,
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      mt: 3,
      mb: 3,
    },
    mb: 4,
  },
  ol: {
    li: {
      fontSize: 3,
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      mt: 3,
      mb: 3,
    },
    mb: 4,
  },
  h1: {
    variant: `text.heading`,
    textTransform: "uppercase",
    fontWeight: `bolder`,
    fontFamily: `sans-serif`,
    fontSize: [5, 6, 8],
  },
  h2: {
    variant: `text.heading`,
    fontSize: [5, 5, 6],
  },
  h3: {
    variant: `text.heading`,
    fontSize: [4, 4, 5],
  },
  h4: {
    variant: `text.heading`,
    fontSize: [3, 3, 4],
  },
  h5: {
    variant: `text.heading`,
    fontSize: 3,
  },
  h6: {
    variant: `text.heading`,
    fontSize: 3,
  },
  blockquote: {
    borderLeftColor: `primary`,
    borderLeftStyle: `solid`,
    borderLeftWidth: `6px`,
    mx: 0,
    pl: 4,
    p: {
      fontStyle: `italic`,
    },
  },
  table: {
    width: `100%`,
    my: 4,
    borderCollapse: `separate`,
    borderSpacing: 0,
    [[`th`, `td`]]: {
      textAlign: `left`,
      py: `4px`,
      pr: `4px`,
      pl: 0,
      borderColor: `muted`,
      borderBottomStyle: `solid`,
    },
  },
  th: {
    verticalAlign: `bottom`,
    borderBottomWidth: `2px`,
    color: `heading`,
  },
  td: {
    verticalAlign: `top`,
    borderBottomWidth: `1px`,
  },
  a: {
    fontSize: 3,
    textDecoration: `none`,
    ":hover": {
      color: "accent",
    },
  },
};

export default styles;
