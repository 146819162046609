export default {
  text: "#000",
  background: "#fff",
  primary: "#8800FF",
  secondary: "#73737D",
  accent: "#CDAAEB",
  highlight: "red",
  purple: "green",
  muted: "rgba(8, 8, 11, 0.15)",
  gray: "#73737D",
  toggleIcon: "#000",
  heading: "#000",
  divide: "rgba(8, 8, 11, 0.15)",

  modes: {
    dark: {
      text: "#fff",
      background: "#000",
      primary: "#FFCC00",
      secondary: "#73737D",
      accent: "#E9DAAC",
      highlight: "red",
      purple: "green",
      muted: "rgba(255, 255, 255, 0.15)",
      gray: "#73737D",
      toggleIcon: "#fff",
      heading: "#fff",
      divide: "rgba(255, 255, 255, 0.15)",
    },
  },
};
